import React, { useState, useEffect } from 'react';
import './HomepageImageGallery.css';

const HomepageImageGallery = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [shuffledImages, setShuffledImages] = useState([]);
    const interval = 4000;
    // const images = [
    //     'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image6',
    //     'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image7',
    //     'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image8',
    // ];

    const images = [
        {
            src: 'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image9.png',
            link: 'https://twentyoneagain.com/category/hair-regrowth-products/', // example link
        },
        {
            src: 'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/weightLoss.jpeg',
            link: 'https://buy.stripe.com/9AQ3eFaV54PncNy5ko', // example link
        },
        {
            src: 'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/image10.png',
            link: 'https://twentyoneagain.com/category/hair-regrowth-products/', // example link
        },
        {
            src: 'https://s3.eu-central-1.wasabisys.com/patient-portal/twentyoneagainImages/weightLoss.jpeg',
            link: 'https://buy.stripe.com/9AQ3eFaV54PncNy5ko', // example link
        },
        // Add more if needed
    ];

    useEffect(() => {
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const shuffled = shuffleArray([...images]);
        setShuffledImages(shuffled);

        setCurrentImageIndex(0); // Reset to first image after shuffling

        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % shuffled.length);
        }, interval);

        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    }, [interval]); // Run only once on mount

    return (
        <div className="image-gallery bannerimage-container">
            {shuffledImages.length > 0 && (
                <a href={shuffledImages[currentImageIndex].link}>
                    <img
                        src={shuffledImages[currentImageIndex].src}
                        alt={`slide-${currentImageIndex}`}
                        style={{ height: '550px', width: '100%', border: '1px solid black' }}
                    />
                </a>
            )}
        </div>
    );
};

export default HomepageImageGallery;
