import React from 'react';
import { Button } from '../Button/Button';
import './StageSection.css';
import { IconContext } from 'react-icons/lib';
import { RiSurgicalMaskFill, RiStethoscopeLine, RiClipboardFill, RiMessage2Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import HomepageImageGallery from '../PromoImageGallery/HomepageImageGallery';

const StageCard = ({reference}) => {

    return (
        <IconContext.Provider value={{ color: '#fff', size: 64 }}>
            <div className='pricing__section py-3 mb-3'>
                <div className='pricing__wrapper'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                            <div className='row justify-content-around'>
                                <Link to='/preop' className='pricing__container-card col-sm-12 col-md-6 col-lg-6 col-xl-4'>
                                <div className='pricing__container-cardInfo' style={{background: 'linear-gradient(45deg, #0660e9 0%, #276afb 100%)'}}>
                                    <div className='icon'>
                                        <RiSurgicalMaskFill />
                                    </div>
                                    <h3>Pre-op</h3>
                                    <div className='pricing__container-features my-auto text-center'>
                                        Find out what you can do and what you can't do before the FUE surgery
                                    </div>
                                    <Button buttonSize='btn--wide' buttonColor='primary'>
                                        View More
                                    </Button>
                                </div>
                                </Link>
                                <Link to='/postop' className='pricing__container-card col-sm-12 col-md-6 col-lg-6 col-xl-4'>
                                    <div className='pricing__container-cardInfo' style={{background: 'linear-gradient(45deg, #0660e9 0%, #276afb 100%)'}}>
                                        <div className='icon'>
                                            <RiStethoscopeLine />
                                        </div>
                                        <h3>Post-op</h3>
                                        <div className='pricing__container-features my-auto text-center'>
                                            Watch videos on the do's and don'ts after FUE surgery, the washing regime and the medications.
                                        </div>
                                        <Button buttonSize='btn--wide' buttonColor='primary'>
                                            View More
                                        </Button>
                                    </div>
                                </Link>
                                <Link to='/faq' className='pricing__container-card col-sm-12 col-md-6 col-lg-6 col-xl-4'>
                                    <div className='pricing__container-cardInfo' style={{background: '#242424'}}>
                                        <div className='icon'>
                                            <RiClipboardFill />
                                        </div>
                                        <h3>FAQ</h3>
                                        <div className='pricing__container-features my-auto text-center'>
                                            Find answers to common questions such as "What should I bring with me on the day of my surgery?"
                                        </div>
                                        <Button buttonSize='btn--wide' buttonColor='blue'>
                                            View FAQ
                                        </Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 px-0'>
                            <HomepageImageGallery />
                            {/* <div className='text-center bg-secondary fw-bold text-white' style={{fontSize:'20px'}}>
                                www.twentyoneagain.com
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </IconContext.Provider>
      );
}

export default StageCard
